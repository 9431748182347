// Variables
@fontsize : 16px;
@fontcolor: #71758E;

// html {
//   scroll-behavior: smooth;
// }

body {

  &.zoom-overlay {
    overflow: hidden;
  }
}

#ads-block {
  min-height: 150px;
  margin: 20px auto;
  text-align: center;
  width: 100%;
}

.widget-block {
      margin-bottom: 20px;

  #ads-block {
    margin: 60px auto;
  }
}

.sticky-top {
  top: 20px;
}

.bdr-btm-dsd {
  border-bottom: 2px dashed #ddd;
}

.bdr-top-dsd {
  border-top: 2px dashed #ddd;
}

// clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);

// radial background
// background: radial-gradient(at right bottom, rgba(29, 39, 54, 0.16) 0%, rgba(29, 39, 54, 0) 72%);

// setting textarea design for title on final preview
// width: 100%;
//     border: none;
//     outline: none;
//     text-decoration-line: underline;
//     line-height: 40px;
//     text-underline-position: under;

// Navbar Button
.navbar-toggler {
  border: none !important;
  outline: none !important;
}

// PrimeNg reset
.ui-dropdown,
.ui-inputtext {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.content-root {
  min-height: 94vh;
}

.setFontSize(@size, @weight, @spacing, @h1, @h2, @h3) {

  // body {
  //   font-size:~"@{@{size}}";

  //   p {
  //     font-size: ~"@{size} !important";
  //   }
  // }

  h1 {
    font-size:~"@{h1} !important";
  }

  h2 {
    font-size:~"@{h2} !important";
  }

  h3 {
    font-size:~"@{h3} !important";
  }

  .blog-heading {

    p {
      font-size:~'@{h1} !important';
    }

  }

  h2 {

    &.feed-title {
      font-size:~"@{size} !important";
    }
  }

  .ql-container {

    .ql-editor {
      font-weight:~"@{weight} !important";
      font-size:~"@{size} !important";

      p {
        // word-spacing:~"@{spacing} !important";

        &.blog-title {
          font-size:~'@{h1} !important';
        }
      }

      h1 {
        font-size:~"@{h1} !important";
      }

      h2 {
        font-size:~"@{h2} !important";
      }

      ol,
      ul {
        margin-bottom: 20px;

        li {
          padding-bottom: 4px;
        }
      }
    }
  }
}


// Hide internal Heading from Blogs on Details Page
.fullview {

  .ql-editor {
    h1 {
      &.blog-title {
        display: none !important;
      }

      &:first-child {
        display: none !important;
      }
    }

    img {

      // &:nth-child(1 of .img-blog) {
      &:first-of-type {
        display: none !important;
      }
    }
  }
}

// Dynamic values for color variable
// @red-md:   #232;
// @red-dk:   #343;

// .setColor(@color) {
//     body.@{color} { background-color: ~"@{@{color}-dk}";
//         #container { background-color: ~"@{@{color}-md}";
//          p { color: ~"@{@{color}-md}"; }
//       }
//     }
// }
// .setColor(~"red"); 

quill-editor {

  .ql-toolbar {

    &.ql-snow {
      background: #090a1d !important;
      margin-bottom: 10px;
      border-radius: 8px;
    }
  }

  .ql-container {

    &.ql-snow {
      border: 2px solid #ddd !important;
      border-radius: 8px;
    }
  }

  i.fas {
    color: #ddd !important;
  }

  span.ql-picker-label {
    color: #ddd !important;
  }

  hr {

    &.section-divider {

      width: 60%;
      position: relative;

      &:after {
        content: 'LF';
        height: 20px;
        width: 40px;
        position: absolute;
        left: 50%;
        top: -13px;
        z-index: 2;
        background: #ddd;
        border-radius: 14px 0px 14px 0px;
        text-align: center;
        font-weight: 700;
        color: #fff;
        box-shadow: 1px 1px 1px 1px #0000000d;
      }
    }
  }
}

// Style for Both Editor and View
.ql-container {
  // padding: 20px;
  min-height: 70vh;

  .ql-editor {
    // font-weight: 400;
    // font-size: 1.2rem !important;
    // letter-spacing: 0.5px;

    p {
      line-height: 2rem;
      // word-spacing: 4px;
      margin-bottom: 20px !important;
      font-family: 'Varela Round', sans-serif;
      font-display: swap;
      // color: #494949;
      // color: #6c757d;
      color: #78797d;

      img {
        margin: 0 auto 40px !important;
        display: block;
      }
    }

    a {
      position: relative;
      // color: #06c !important;
      color: #356ff5 !important;
      margin: 8px 28px 8px 0px;
      display: inline-flex;

      &:before {
        content: "\f35d";
        color: #06c;
        color: #356ff5 !important;
        font-weight: 600;
        font-size: 12px;
        position: absolute;
        top: -14px;
        right: -16px;
        font-family: 'Font Awesome 5 Free';
      }

      &:hover {

        &:before {
          right: -18px;
          top: -15px;
        }
      }
    }

    h1 {
      // font-size: 30px !important;
      margin-bottom: 20px !important;
      font-family: 'Bree Serif', serif !important;
      font-display: swap;
    }

    h2 {
      // font-size: 24px !important;
      margin-bottom: 20px !important;
      font-family: 'Bree Serif', serif !important;
      font-display: swap;
    }

    ul,
    ol {
      margin-bottom: 20px !important;
      line-height: 2rem;
      margin-bottom: 20px !important;
      font-family: 'Varela Round', sans-serif;
      color: #494949;
    }

    .img-blog {
      margin: 0 auto 40px !important;
      display: block;
      border-radius: 6px !important;
      height: auto;
      max-height: 338px;
      width: auto;
      max-width: 100%;
      cursor: zoom-in;
    }

    blockquote {
      color: #777;
      font-style: italic;
      margin: 0 !important;
    }

    .blog-title {

      &:before {
        content: none;
      }

      &.isEmpty {

        &:before {
          content: 'Enter Title For Your Blog Post';
          pointer-events: none;
          z-index: -1;
          color: #ddd;
          position: absolute;
        }
      }
    }

    .ql-syntax {
      font-size: 16px !important;
      border-radius: 6px 0px 6px 6px !important;
      background-color: #192448 !important;
      position: relative;
      padding: 12px 16px;

      .copy-button {
        position: absolute;
        top: -29px;
        right: 0;
        border-radius: 4px 4px 0 0;
        background: #192448;
        border: none;
        outline: none;
        color: white;
        font-family: 'Varela Round';
        padding: 6px 12px;
        font-size: 13px;
      }

      &:has(.copy-button) {
        margin: 40px 20px 20px !important;
      }
    }

    &.ql-blank {

      .blog-title {
        position: relative;

        &:before {
          content: 'Enter Title For Your Blog Post';
          pointer-events: none;
          z-index: -1;
          color: #ddd;
          position: absolute;
        }

      }

      &:before {
        content: none;
      }
    }
  }
}

p-inputswitch {

  .p-inputswitch {
    width: 2.5rem;
    height: 0.6rem;

    .p-inputswitch-slider {
      background: none !important;
      border: 1px solid #c3a0fb;
      box-shadow: inset 0px 0px 2px 0px #405bdf !important;

      &:before {
        background: linear-gradient(180deg, #cea2fd, #6495ed) !important;
        width: 0.95rem;
        height: 0.95rem;
        left: 0.25rem;
        margin-top: -0.485rem;
        border-radius: 4px;
        transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
      }
    }

    &.p-inputswitch-checked {

      .p-inputswitch-slider {

        &:before {
          transform: translateX(1.05rem);
        }
      }
    }
  }
}

// Pagination UI
// Pagination Css
// pagination-controls {

//   .ngx-pagination {
//     text-align: center;
//     // box-shadow: 2px 2px 4px 1px #ddd;
//     border-radius: 4px;
//     padding: 6px;
//     margin: 10px auto !important;
//     font-weight: 700;

//     li {
//       margin: 0 6px !important;
//       border-radius: 4px !important;
//       box-shadow: 0px 1px 4px 1px #efefef;
//       // box-shadow: 1px 3px 6px 0px #ded5d5;
//       a {
//         // color: #ddd !important;
//         color: #44a6ff !important;
//         outline: none !important;
//         border-radius: 4px !important;

//         &:hover {
//           color: #ffffff !important;
//           background: #2199e8 !important;
//           box-shadow: 2px 2px 6px 0px #d4d3d3;
//         }
//       }

//       &.pagination-previous {

//         a {

//           &:before {
//             content: '\f104' !important;
//             // font-family: 'fontawesome';
//             font-family: "Font Awesome 5 Free";
//             margin: 0 2px !important;
//           }
//         }

//         &.disabled {

//           color: #ddd !important;

//           &:before {
//             content: '\f104' !important;
//             // font-family: 'fontawesome';
//             font-family: "Font Awesome 5 Free";
//             margin: 0 2px !important;
//           }
//         }
//       }

//       &.pagination-next {

//         a {
//           content: '\f104' !important;
//           &:after {
//             content: '\f105' !important;
//             // font-family: 'fontawesome';
//             font-family: "Font Awesome 5 Free";
//             margin: 0 2px !important;
//           }
//         }


//         &.disabled {

//           color: #ddd !important;

//           &:after {
//             content: '\f105' !important;
//             // font-family: 'fontawesome';
//             font-family: "Font Awesome 5 Free";
//             margin: 0 2px !important;
//           }
//         }
//       }

//       &.current {
//         box-shadow: 2px 2px 6px 0px #d4d3d3;
//       }
//     }
//   }

// }

p-paginator {

  .ui-paginator,
  .p-paginator {
    background-color: transparent !important;
    border: none !important;
    font-weight: 900;

    .ui-paginator-element,
    .p-paginator-element {

      // color: #44a6ff !important;
      color: #006ff9 !important;
      border-radius: 4px !important;
      margin-right: 8px !important;
      outline: none !important;
      font-weight: 600;

      &.ui-state-active,
      &.p-highlight {
        // background-color: #44a6ff !important;
        background-color: #006ff9 !important;
        color: #ffffff !important;
        box-shadow: 2px 2px 6px 0px #d4d3d3;
      }

      &.ui-state-disabled,
      &.p-disabled {
        color: #848484 !important;
      }

      &:not(&.ui-state-disabled),
      &:not(&.p-disabled) {

        &:hover {
          color: #ffffff !important;
          // background-color: #44a6ff !important;
          background-color: #006ff9 !important;
          box-shadow: 2px 2px 6px 0px #d4d3d3;
        }
      }

      &:focus {
        box-shadow: none !important;
      }

    }

    .p-paginator-icon {

      &.pi-angle-right {

        &:before {
          content: "\e905" !important;
        }
      }

      &.pi-angle-double-right {

        &:before {
          content: "\e917" !important;
        }
      }

      &.pi-angle-left {

        &:before {
          content: "\e904" !important;
        }
      }

      &.pi-angle-double-left {

        &:before {
          content: "\e916" !important;
        }
      }
    }
  }
}

// Scollbar Less

::-webkit-scrollbar {
  width: 18px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 5px solid #fff;
  background-image: linear-gradient(to bottom, #0acffe 0%, #495aff 100%);
}

/* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
//     background: #b30000; 
//   }


::-webkit-scrollbar-button {
  background: no-repeat transparent;
  background-size: 10px;
  background-position: center bottom;
}

::-webkit-scrollbar-button:vertical:decrement {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%235a6268'><polygon points='0,50 100,50 50,0'/></svg>");
}

::-webkit-scrollbar-button:vertical:increment {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%235a6268'><polygon points='0,0 100,0 50,50'/></svg>");
}

::selection {
  background: #eef3f9;
  color: black;
}

a {
  text-decoration: none !important;
}


.innerWrapper div,
.inner-scrollbar {

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f4f4 !important;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px !important;
    border: none !important;
    background-color: #d6ddff !important;
    background-image: none !important;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

// PrimeNg CSS
p-dropdown {

  .ui-dropdown {
    border: none !important;

    input {

      &::placeholder {
        font-size: 12px;
        color: #ddd;
      }
    }
  }

  .p-dropdown {

    &.toolbar-menu-item {
      border: none !important;
      background: none !important;
      padding: 5px 10px;

      p-dropdownitem {

        .p-highlight {
          color: #416dea !important;
        }
      }

      .p-dropdown-panel {
        transform: translateY(-20px);
      }

      .p-dropdown-items {
        display: flex;
      }

      .p-dropdown-panel {

        &.panel-top {
          transform: translateY(-20px);
        }

        &.panel-bottom {
          transform: translateY(20px);
        }
      }

      .p-dropdown-trigger {

        .fa-pen {
          font-size: 20px;
        }

        .fa-caret-up {
          position: absolute;
          top: 4px;
          -ms-transform: rotate(45deg);
          transform: rotate(47deg);
          font-size: 10px;
          left: 13px;
        }

        &:has(.fa-caret-up) {
          font-size: 14px !important;
          position: absolute;
          left: 20px;
          top: -6px;
          transition: all 0.5s;
        }
      }

      .p-inputtext {

        &:has(.pen-item),
        &:has(.ereaser-item) {
          font-size: 18px;
          color: #767676;
          padding: 0;
        }
      }

      &.active {

        .p-inputtext {
          color: #416dea !important;
        }
      }

      &.p-focus {
        box-shadow: none !important;
        border: none !important;
        outline: none !important;

      }

      &:hover {
        border: none !important;

        &:not(.disabled) {
          .p-inputtext {
            color: #416dea !important;
          }

          .p-dropdown-trigger {

            .fa-caret-up {
              top: 3px;
              left: 14px;
            }
          }
        }
      }

      &.p-dropdown-open {

        .p-inputtext {
          color: #416dea !important;
        }
      }

      &:focus {

        &:not(.disabled) {

          .p-dropdown-trigger {

            .fa-caret-up {
              top: 3px;
              left: 14px;
              color: #416dea !important;
            }
          }
        }
      }

      &.disabled {
        cursor: not-allowed !important;
      }
    }
  }

  &:has(.disabled) {
    cursor: not-allowed !important;
  }
}

p-colorpicker {

  .p-colorpicker {
    position: relative;
    font-size: 18px;
    color: #767676;
    background: none;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 5px 10px;

    .p-colorpicker-preview {
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }
}

p-calendar {

  .ui-inputtext {
    border: none !important;
  }

  input {

    &::placeholder {
      font-size: 12px;
      color: #ddd;
    }
  }
}

ngx-loading-bar {
  .ngx-spinner {

    .ngx-spinner-icon {
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      position: relative;
      position: fixed;
      pointer-events: none;
    }
  }
}

p-dialog {

  .p-dialog-mask {

    &:has(.p-dialog-content) {
      background: rgba(0, 0, 0, 0.4);
      pointer-events: auto !important;
    }
  }

  .p-dialog {
    border-radius: 8px;
    overflow: hidden;
    font-family: 'Varela Round';
    font-size: 20px;
    background: #ffffff;

    .p-dialog-content {
      padding: 30px;
    }

    .p-dialog-footer {
      padding: 0 10px 16px 10px;
    }
  }
}

button.mat-focus-indicator {
  outline: none;
  box-shadow: none !important;

  .mat-button-wrapper {
    img {
      width: 50% !important;
    }
  }
}

// Button styles
.btn {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid red;
  border-radius: 0.6em;
  color: red;
  cursor: pointer;
  // display: flex;
  align-self: center;
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 8px;
  padding: 14px 20px;
  text-decoration: none;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-display: swap;
  font-weight: 700;
  outline: none;

  &.primary {
    border-color: #057bf6;
    color: #057bf6;
    background-image: linear-gradient(45deg, #057bf6 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: background 300ms ease-in-out;

    &:hover,
    &:focus {

      &:not(.disabled) {
        background-position: 0;
        color: #fff;
        outline: none;
        box-shadow: none;
      }
    }
  }

  &:hover,
  &:focus {

    &:not(.disabled) {
      color: #fff;
      outline: none;
      box-shadow: none;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}

// image wrapper
.img-wrap {

  img {
    width: 100%;
  }
}

// skeleton loader style
.ph-item {
  border-radius: 10px !important;
}

// Fullscreen Mode
:fullscreen,
::backdrop {
  background-color: rgba(255, 255, 255, 0);
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  // .setFontSize(~"14px");
  .setFontSize('16px', '400', '2px', '24px', '18px', '16px');

  .ql-syntax {
    margin: 0 0 20px !important;
  }

  .navbar-nav {
    align-items: start !important;
  }
}

@media (min-width: 568px) and (max-width: 575px) {
  // pagination-controls {

  //   .ngx-pagination {
  //     width: 50%;
  //   }
  // }
}

// Small devices (landscape phones, 576px and up)

@media (min-width: 576px) and (max-width: 767.98px) {

  .setFontSize('16px', '400', '2px', '24px', '18px', '16px');

  // pagination-controls {

  //   .ngx-pagination {
  //     width: 50%;
  //   }
  // }

  .navbar-nav {
    align-items: start !important;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

  .setFontSize('18px', '400', '2px', '30px', '20px', '16px');

  // pagination-controls {

  //   .ngx-pagination {
  //     width: 60%;
  //   }
  // }

  .navbar-nav {
    align-items: start !important;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

  .setFontSize('18px', '400', '2px', '30px', '20px', '16px');

  // pagination-controls {

  //   .ngx-pagination {
  //     width: 50%;
  //   }
  // }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .setFontSize('18px', '400', '2px', '32px', '20px', '15px');

  // pagination-controls {

  //   .ngx-pagination {
  //     width: 50%;
  //   }
  // }
}
